.slick-slider {
  position: relative; }

.slick-list {
  overflow: hidden; }

.slick-track {}

.slick-slide {
  float: left;
  outline: 0; }

/* Arrows */

.slick-arrow {
  position: absolute;
  top: 45%;
  width: 25px;
  height: 45px;
  border: 0;
  outline: 0;
  cursor: pointer;
  text-indent: -9999px;

  &.slick-prev {
    left: 0;
    background: url("#{$img}carousel-left.svg") no-repeat;
    margin-left: -20px; }

  &.slick-next {
    right: 0;
    background: url("#{$img}carousel-right.svg") no-repeat;
    margin-right: -20px; }

  &.slick-disabled {
    opacity: 0; } }

/* Dots */

.slick-dots {
  text-align: center;
  font-size: 0;

  li {
    display: inline-block;
    margin: 0 5px;

    &:before {
      display: none; }

    &.slick-active {
      button {
        background-color: $color-text; } } }

  button {
    width: 16px;
    height: 16px;
    padding: 0;
    border: 2px solid $color-text;
    outline: 0;
    border-radius: 50%;
    background-color: #ffffff; } }

/* Custom */

.carousel-tabs {}
