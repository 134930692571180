@mixin transition($transition) {
  -webkit-transition: $transition;
  -moz-transition: $transition;
  -o-transition: $transition;
  -ms-transition: $transition;
  transition: $transition; }

@mixin transform($transform) {
  -webkit-transform: $transform;
  -moz-transform: $transform;
  -o-transform: $transform;
  -ms-transform: $transform;
  transform: $transform; }

@mixin transform-origin($transform-origin) {
  -webkit-transform-origin: $transform-origin;
  -moz-transform-origin: $transform-origin;
  -o-transform-origin: $transform-origin;
  -ms-transform-origin: $transform-origin;
  transform-origin: $transform-origin; }

@mixin appearance($value) {
  -webkit-appearance: $value;
  -moz-appearance: $value;
  appearance: $value; }

@mixin user-select($value) {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

@mixin linear-gradient($gradient, $fallback) {
  background: $fallback;
  background: linear-gradient($gradient); }

@mixin radial-gradient($gradient, $fallback) {
  background: $fallback;
  background: radial-gradient($gradient); }

@mixin bgrgba($color, $opacity) {
  background-color: $color;
  background-color: rgba($color, $opacity);
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=#{$opacity * 100}); }

@mixin filter($filter) {
  filter: $filter;
  -webkit-filter: $filter; }

@mixin zindex($number) {
  z-index: $number * 100; }

@mixin placeholder($color) {
  &::-webkit-input-placeholder {
    color: $color; }

  &::-moz-placeholder {
    color: $color; }

  &:-moz-placeholder {
    color: $color; }

  &:-ms-input-placeholder {
    color: $color; } }

@mixin position($position: "static", $top: false, $left: false, $right: false, $bottom: false) {
  position: $position;

  @if $top {
    top: $top; }

  @if $left {
    left: $left; }

  @if $right {
    right: $right; }

  @if $bottom {
    bottom: $bottom; } }

@mixin overlay($position: fixed) {
  width: 100%;
  height: 100%;
  @include position($position, 0, 0); }

@mixin valign($position: middle) {
  .valign {
    display: inline-block;
    vertical-align: $position; }

  &::after {
    content: "";
    display: inline-block;
    vertical-align: $position;
    width: 0px;
    height: 100%;
    margin-left: -.25em; } }

@mixin clearmarginfirst($side: top) {
  &:first-child {
    margin-#{$side}: 0; } }

@mixin hidetext {
  text-indent: -9999px;
  white-space: nowrap; }

@mixin clearfix {
  &::before, &::after {
    content: "";
    display: table; }

  &::after {
    clear: both; } }

@mixin justify {
  text-align: justify;

  &::after {
    content: "";
    overflow: hidden;
    visibility: hidden;
    display: inline-block;
    width: 100%;
    height: 0; } }

/* Media Queries */

@mixin bp($point, $type: "min", $dimension: "width") {
  @if $type == "max" {
    $point: $point - 1; }

  @media (#{$type}-#{$dimension}: $point) {
    @content; } }

/* Animation */

@mixin animation($animation) {
  -webkit-animation: $animation;
  -moz-animation: $animation;
  -o-animation: $animation;
  animation: $animation; }

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content; }
  @-moz-keyframes #{$name} {
    @content; }
  @-o-keyframes #{$name} {
    @content; }
  @keyframes #{$name} {
    @content; } }
