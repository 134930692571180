ul,
ol {
  padding-left: 0;
  margin: 40px 0;

  &:first-child {
    margin-top: 0; }

  &:last-child {
    margin-bottom: 0; } }

ul {
  li {
    position: relative;
    padding-left: 1.25em;
    margin: 5px 0;
    list-style: none;

    &::before {
      content: "\2981";
      margin-top: -.18em;
      color: $color-green;
      font-size: 1.25em;
      @include position(absolute, 0, 0); } } }

ol {
  counter-reset: ol-counter;

  li {
    position: relative;
    padding-left: 1.25em;
    margin: 5px 0;
    list-style: none;

    &:before {
      content: counter(ol-counter) ".";
      counter-increment: ol-counter;
      color: $color-green;
      font-weight: 400;
      font-size: 1em;
      @include position(absolute, 0, 0); } } }

/**/

dl {
  margin: 40px 0;
  line-height: 2;
  @include clearfix; }

dt {
  float: left;
  padding-top: .375em;
  clear: left;
  color: $color-green;
  font-size: 75%; }

dd {
  float: left;
  margin-left: 1em;
  color: #000000; }

/**/

blockquote {
  position: relative;
  padding: 20px 30px;
  margin: 40px 0;
  background: #e0f8f0;
  font-size: 21px;
  font-weight: 400;
  color: #555555;

  @include bp($screen-md) {
    margin: 40px 40px 40px 60px; }

  &:before {
    @include bp($screen-md) {
      content: "";
      position: absolute;
      right: 100%;
      top: 25px;
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 15px 25px 15px 0px;
      border-color: transparent #e0f8f0 transparent transparent; } } }

/**/

.iframe {
  position: relative;
  overflow: hidden;
  height: 0;
  padding-top: 35px;
  padding-bottom: 56.25%;

  iframe {
    width: 100%;
    height: 100%;
    @include position(absolute, 0, 0); } }

/**/

p {
  margin: 20px 0;

  &:first-child {
    margin-top: 0; }

  &:last-child {
    margin-bottom: 0; } }
