a,
.link {
  border: 0;
  border-bottom: 1px solid transparent;
  color: $color-green;
  text-decoration: none;
  cursor: pointer;

  &:visited {
    color: $color-green; }

  &:hover,
  &:active {
    border-bottom-color: #95fbdd;
    color: $color-green; }

  &.with-image {
    border: 0;
    text-decoration: none;

    img {
      border: 0; } } }

.link-pseudo {
  border-bottom: 1px dashed; }
