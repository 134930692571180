button {
  cursor: pointer; }

.button {
  vertical-align: top;
  display: inline-block;
  border: 0;
  border-bottom: 0;
  outline: 0;
  background: none;
  text-decoration: none;
  text-align: center;
  font-family: $font1;
  line-height: 1;
  cursor: pointer;
  @include transition((background-color .15s, color .15s, border-color .15s, box-shadow .15s));
  @include user-select(none); }

.button-primary {
  width: auto;
  height: 56px;
  border: 1px solid $color-green;
  border-radius: 8px;
  padding: 0 40px;
  color: $color-green;
  font-size: 22px;
  font-weight: 300;
  line-height: 56px;

  &:hover {
    color: $color-green;
    border-color: $color-green;
    box-shadow: inset 0 0 0 1px $color-green; }

  &:active,
  &:focus {
    color: $color-green;
    border-color: $color-green; }

  &:visited {
    color: $color-green; }

  &.loading {
    background: url("#{$img}loader.gif") no-repeat center center; }

  &.success {
    background: $color-green;
    color: #ffffff;
    pointer-events: none;
    cursor: default; } }

.button-secondary {
  width: auto;
  height: 56px;
  border: 1px solid #cccccc;
  border-radius: 8px;
  padding: 0 40px;
  color: #ecf0f1;
  font-size: 22px;
  font-weight: 300;
  line-height: 56px;

  &:hover {
    color: #ffffff;
    border-color: #ffffff;
    box-shadow: inset 0 0 0 1px #ffffff; }

  &:active,
  &:focus {
    color: #ffffff;
    border-color: #ffffff; }

  &:visited {
    color: #ffffff; } }

.button-order {
  width: 100%;
  height: 56px;
  border: 1px solid $color-green;
  border-radius: 8px;
  padding: 0 40px;
  background-color: $color-green;
  color: #ffffff;
  font-size: 22px;
  font-weight: 300;
  line-height: 56px;

  &:hover {
    color: $color-green;
    background-color: #ffffff;
    box-shadow: inset 0 0 0 1px $color-green; }

  &:active,
  &:focus {
    color: $color-green;
    background-color: #ffffff;
    border-color: $color-green; }

  &:visited {
    color: #ffffff; }

  &.loading {
    background: url("#{$img}loader.gif") no-repeat center center; }

  &.success {
    background: $color-green;
    color: #ffffff;
    pointer-events: none;
    cursor: default; } }
