.menu-toggle {
  display: none;
  float: right;
  width: 40px;
  height: 40px;
  margin-top: 25px;
  border: 1px solid #e1e1e1;
  text-align: center;
  cursor: pointer;
  @include transform(translateX(0px));
  @include valign();

  &.active {}

  .icon {
    width: 28px;
    height: 20px;
    fill: #000000; }

  @include bp($screen-md, max) {
    display: block; } }

.menu-toggle-close {
  display: block;
  width: 45px;
  height: 45px;
  margin-right: 50px;
  text-align: center;
  color: #ffffff;
  font-size: 24px;
  font-weight: 600;
  line-height: 45px;
  cursor: pointer;
  @include position(absolute, 0, false, 0);
  @include transition(color .15s);

  &::before {
    content: "✕"; }

  &:hover {
    color: #b0efe0; } }

/**/

.menu-header {
  float: left;
  height: $header-height;
  margin-left: 50px;
  line-height: $header-height;

  .menu-item {
    margin-left: 50px;
    color: $color-text;
    font-weight: 400;
    font-size: 18px;
    @include clearmarginfirst(left);

    @include bp($screen-lg, max) {
      font-size: 16px;
      margin-left: 15px; } }

  a.menu-item {
    &:hover {
      border-bottom-color: #9ed8c9;
      color: $color-green; } }

  span.menu-item,
  .menu-item.active {
    color: $color-green; }

  @include bp($screen-md, max) {
    float: none;
    height: auto;
    padding: 25px 0;
    border-top: 1px solid #4cd2c0;
    text-align: center;
    margin-left: 0;
    line-height: $line-height;

    .menu-item {
      display: block;
      margin-top: 30px;
      margin-left: 0;
      color: #ffffff;
      font-size: 22px;
      @include clearmarginfirst(top); }

    a.menu-item {
      &:hover {
        border-bottom-color: transparent;
        color: #b0efe0; } }

    span.menu-item,
    .menu-item.active {
      color: #b0efe0; } } }

/**/

.menu-services {
  margin-bottom: 30px;
  background-color: #00bfa5;

  .container {
    padding-left: 0; }

  ul {
    display: table;
    width: 100%;
    margin: 0;
    padding: 0; }

  li {
    position: relative;
    vertical-align: middle;
    display: table-cell;
    width: percentage(1/6);
    padding-left: 0;

    &:before {
      display: none; } }

  .menu-item {
    display: block;
    height: 58px;
    padding-top: 8px;
    padding-left: 20px;
    padding-right: 20px;
    color: #ffffff;
    font-weight: 400;
    font-size: 18px;
    line-height: 1;

    .icon {
      vertical-align: -4px;
      display: inline-block;
      height: 40px;
      width: 40px; }

    span {
      display: inline-block;
      margin-left: 5px;
      max-width: 70px; } }

  a.menu-item {
    &:hover {
      border-bottom-color: transparent;
      background-color: #00927e;
      color: #ffffff; } }

  span.menu-item,
  .menu-item.active {
    border-bottom-color: transparent;
    background-color: #00927e;
    color: #ffffff; }

  .menu-sub {
    display: none;
    width: 240px;
    padding: 15px 20px;
    border: 3px solid #00927e;
    background-color: $color-green;
    box-shadow: inset 0 3px 7px rgba(0, 0, 0, 0.16);
    @include position(absolute, 100%, 0);
    @include zindex(1);

    .menu-sub-item {
      display: block;
      height: 40px;
      border-top: 1px solid #00ae95;
      color: #ffffff;
      font-size: 16px;
      font-weight: 400;
      line-height: 40px;
      @include transition(transform .3s);

      &:first-child {
        border-top: 0; } }

    a.menu-sub-item {
      &:hover {
        border-bottom-color: transparent;
        color: #ffffff;
        @include transform(translateX(5px)); } }

    span.menu-sub-item,
    .menu-sub-item.active {
      color: #ffffff; } }

  @include bp($screen-md, max) {
    display: none; } }

/**/

.menu-services-hidden {
  display: none;

  @include bp($screen-md, max) {
    display: block;
    padding-top: 25px;
    border-top: 1px solid #4cd2c0;
    text-align: center;

    .menu-item {
      display: inline-block;
      width: 190px;
      padding: 15px 10px;
      color: #ffffff;
      font-weight: 400;
      font-size: 18px;
      line-height: 1;

      .icon {
        vertical-align: -4px;
        height: 40px;
        width: 40px; }

      span {
        display: block;
        margin-top: 5px; } }

    a.menu-item {
      &:hover {
        border-bottom-color: transparent;
        color: #b0efe0; } }

    span.menu-item,
    .menu-item.active {
      border-bottom-color: transparent;
      color: #b0efe0; } } }

/**/

.menu-models {
  margin-bottom: 20px;
  text-align: center;

  @include bp($screen-sm, max) {
    display: none; }

  .menu-item {
    display: inline-block;
    padding: 5px 10px;
    margin-left: 15px;
    margin-bottom: 5px;
    border-radius: 10px;
    color: $color-green;
    font-weight: 400;
    font-size: 18px;
    line-height: 1;
    @include clearmarginfirst(left); }

  a.menu-item {
    &:hover {
      background-color: #f7f7f7;
      border-bottom-color: transparent;
      color: $color-green; } }

  span.menu-item,
  .menu-item.active {
    background-color: #f0f0f0;
    color: #929292; } }

.menu-models-select {
  display: none;

  @include bp($screen-sm, max) {
    display: block;
    margin-bottom: 20px; } }


