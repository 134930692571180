$wp: true;

$img: "img/";

$font1: "Myriad Set Pro", "Open Sans", "Trebuchet MS", sans-serif;
$font2: "Myriad Set Pro", "Open Sans", "Trebuchet MS", sans-serif;

$font-size: 18px;
$line-height: 1.33;

$header-height: 94px;

/* Colors */

$color-text: #4c4c4c;

$color-gray: #d7d7d7;

$color-red: #c45b5c;
$color-green: #00bfa5;
$color-blue: #b3e0f8;
$color-yellow: #f8f870;
