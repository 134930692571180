body.page-service {}

/* Sections */

.section-service-main {
  padding: 30px 30px 35px;
  border: 1px solid #e6e6e6;

  @include bp($screen-sm, max) {
    display: block; } }

.section-service-main-image {
  vertical-align: middle;
  display: table-cell;
  width: 400px;

  @include bp($screen-sm, max) {
    display: block;
    width: 100%;
    height: auto;
    margin-bottom: 40px;
    text-align: center; } }

.section-service-main-text {
  vertical-align: middle;
  display: table-cell;
  padding-left: 60px;

  .block-icons {
    p {
      min-height: 55px;
      padding-top: 10px; } }

  @include bp($screen-sm, max) {
    display: block;
    padding-left: 0; }

  .heading {
    text-align: left;
    background-position: bottom left;

    @include bp($screen-sm, max) {
      text-align: center;
      background-position: bottom center; } } }

.section-service-main-meta {
  margin-top: 25px;
  font-size: 25px;
  @include clearfix;

  .icon {
    vertical-align: -20px;
    margin-right: 15px;
    color: $color-green; }

  .form-select {
    float: right;
    width: 350px;
    margin-top: 2px;

    @include bp($screen-lg, max) {
      float: none;
      width: 100%;
      margin-top: 20px; } } }

.section-service-main-price {
  margin-top: 25px;

  strong {
    color: #4d4d4d;
    font-size: 30px;
    font-weight: 600;

    @include bp($screen-lg, max) {
      display: block; } } }

.section-service-main-delivery {
  margin-top: 25px;
  font-weight: 600; }

.section-service-main-order {
  width: 400px;
  margin-top: 25px;

  .button {
    width: auto; }

  @include bp($screen-sm, max) {
    width: 100%; }

  p {
    margin-top: 10px; } }

/**/

.section-service-table {
  padding-top: 50px; }

.section-service-table-footer {
  margin-top: 30px;
  text-align: center;
  color: #808080;

  .section-service-main-order {
    margin: 0 auto; } }

/**/

.section-service-icons {
  padding-top: 50px;
  padding-bottom: 50px;
  margin-top: 50px;
  background-color: #f2f2f2; }

/**/

.section-service-desc {
  padding-bottom: 50px;
  background-color: #f2f2f2;
  text-align: center;

  .container {
    position: relative;
    padding-top: 50px;
    border-top: 2px solid #d9d9d9;

    &:before {
      content: "";
      width: 100%;
      margin-top: 2px;
      border-top: 1px solid #d9d9d9;
      @include position(absolute, 0, 0); } }

  &.iphone {
    margin-top: 50px;
    padding-bottom: 150px;
    background: url("#{$img}box.png") no-repeat bottom center #f2f2f2;

    .container {
      border-top: 0;

      &:before {
        display: none; } }

    p {
      max-width: 890px;
      margin-left: auto;
      margin-right: auto; } } }

/**/

.section-service-bottom {
  margin-top: 50px;
  padding-bottom: 50px;
  background-color: #f2f2f2;
  text-align: center;

  .container {
    position: relative;
    padding-top: 50px;

    &:last-child {
      margin-top: 50px;
      border-top: 2px solid #d9d9d9;

      &:before {
        content: "";
        width: 100%;
        margin-top: 2px;
        border-top: 1px solid #d9d9d9;
        @include position(absolute, 0, 0); } } } }

/**/

.section-service-scheme {
  padding-top: 45px;
  padding-bottom: 45px;
  border-top: 1px solid #dddddd;
  text-align: center;

  .block-scheme {
    max-width: 500px;
    margin: 0 auto 80px; } }

/**/

.section-service-tabs {
  @include bp($screen-600, max) {
    .container {
      padding-left: 0;
      padding-right: 0; }

    .tabs-control {
      padding-left: 0;
      padding-right: 0; } } }
