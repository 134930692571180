.pagination {
  margin-top: 50px; }

.pagination-item {
  display: inline-block;
  width: 45px;
  margin-left: 5px;
  border: 1px solid #dce0e0;
  text-align: center;
  font-size: 16px;
  line-height: 43px;
  color: #171717;
  @include transition(border-color .15s);

  &:first-child {
    margin-left: 0; }

  &.prev,
  &.next {
    width: auto; } }

a.pagination-item {
  &:visited {
    color: #171717; }

  &:hover {
    border-color: #171717;
    color: #171717; } }

span.pagination-item {
  border-color: #ffa800; }


