.wrapper {
  overflow: hidden; }

/**/

.section-socials {
  margin-top: 50px;
  text-align: center;

  .container {
    position: relative;
    padding-top: 50px;
    border-top: 2px solid #e5e5e5;

    &:before {
      content: "";
      width: 100%;
      margin-top: 2px;
      border-top: 1px solid #e5e5e5;
      @include position(absolute, 0, 0); } }

  p {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto; }

  &.clear {
    margin-top: 0;

    .container {
      border-top: 0;

      &:before {
        display: none; } } } }

.section-socials-links {
  margin-top: 50px;

  a {
    display: inline-block;
    width: 50px;
    height: 50px;
    margin: 0 5px;
    color: #c5c5c5;

    .icon {
      width: 100%;
      height: 100%; }

    &:hover {
      color: $color-green;
      border-bottom-color: transparent; } } }

/**/

.section-map {
  height: 530px;
  margin-top: 50px;

  @include bp(900px, max, height) {
    height: 300px; } }

/**/

.section-contacts {
  padding-top: 50px; }

/**/

.text-phone {
  color: #000000;
  font-size: 27px;
  font-weight: 600; }

/**/

.block-icons {
  margin-bottom: 40px;

  p {
    position: relative;
    padding-left: 80px;
    min-height: 80px; }

  .icon {
    position: absolute;
    top: 0;
    left: 0; } }

/**/

.block-scheme {
  text-align: left;
  counter-reset: block-counter;

  div {
    position: relative;
    padding-left: 80px;
    margin-top: 40px;
    @include clearmarginfirst(top);

    &:before {
      content: counter(block-counter);
      position: absolute;
      top: 0;
      left: 0;
      counter-increment: block-counter;
      color: $color-green;
      width: 50px;
      height: 50px;
      border: 1px solid $color-green;
      border-radius: 50%;
      text-align: center;
      line-height: 50px; } } }
