.form-select {
  position: relative;
  display: inline-block;
  width: 100%;

  select {
    @include appearance(none);

    &::-ms-expand {
      display: none; } }

  .form-control {
    border-radius: 0;
    background-color: #ecf0f1;
    color: #808080; }

  &:after {
    content: "";
    display: block;
    margin-top: 20px;
    margin-right: 15px;
    width: 22px;
    height: 12px;
    background: url("#{$img}arrow-down.png") no-repeat;
    pointer-events: none;
    @include position(absolute, 0, false, 0); } }
