table.responsive {
  @include bp($screen-md, max) {
    table, thead, tbody, th, td, tr {
      display: block; }

    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px; }

    tr {
      border-top: 3px solid $color-green;
      border-bottom: 3px solid $color-green;

      &:first-child {
        border-bottom: 0; }

      &:last-child {
        border-top: 0; } }

    td {
      height: auto;
      padding: 0 0 15px;
      text-align: center;

      &:before {
        content: attr(data-heading);
        display: block;
        height: 40px;
        margin-bottom: 15px;
        background-color: #dbe1ea;
        text-align: center;
        font-size: 12px;
        line-height: 40px; } } } }
