form {
  margin: 0; }

label {
  display: block;
  line-height: 1; }

.form {
  position: relative; }

/* Form types */

.form-callback {
  width: 300px;
  margin: 0 auto;
  text-align: center;

  .button {
    width: 280px;

    @include bp($screen-sm, max) {
      width: 100%;
      font-size: 18px; } }

  @include bp($screen-sm, max) {
    width: 100%; } }

.form-contacts {
  margin-left: -15px;
  margin-right: -15px;
  font-size: 0;
  @include clearfix;

  @include bp($screen-md, max) {
    margin-left: 0;
    margin-right: 0; }

  .button {
    width: 280px; }

  .form-group {
    padding: 0 15px;

    @include bp($screen-md, max) {
      padding: 0; } }

  .form-submit {
    text-align: center; }

  .form-group-column {
    display: inline-block;
    width: percentage(1/3);

    @include bp($screen-md, max) {
      display: block;
      width: 100%; } } }

.form-order {
  width: 300px;
  margin: 0 auto;
  text-align: center;

  .button {
    width: 280px;

    @include bp($screen-xs, max) {
      width: 100%; } }

  @include bp($screen-sm, max) {
    width: 100%; } }

/* Form group */

.form-group {
  margin-top: 25px;
  position: relative; }

.form-submit {
  margin-top: 25px; }

/* Placeholder */

.form-group .placeholder {
  opacity: 0;
  display: inline-block;
  padding: 2px 10px;
  margin-left: 10px;
  border-radius: 4px;
  background-color: #ffffff;
  font-size: 12px;
  font-weight: 400;
  @include position(absolute, 10px, 0);
  @include zindex(4); }

/* Error label */

.form-group label.error {
  overflow: hidden;
  display: none;
  height: 0; }
