.heading {}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  display: block;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1;

  &:first-child {
    margin-top: 0; } }

h1, .h1 {
  padding-bottom: 25px;
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: center;
  background: url("#{$img}line.png") no-repeat bottom center;
  color: #000000;
  font-weight: 300;
  font-size: 41px; }

h2, .h2 {
  padding-bottom: 25px;
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: center;
  background: url("#{$img}line.png") no-repeat bottom center;
  color: #000000;
  font-weight: 300;
  font-size: 41px; }

h3, .h3 {
  padding-bottom: 25px;
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: center;
  background: url("#{$img}line.png") no-repeat bottom center;
  color: #000000;
  font-weight: 300;
  font-size: 35px; }

h4, .h4,
h5, .h5,
h6, .h6 {
  padding-bottom: 25px;
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: center;
  background: url("#{$img}line.png") no-repeat bottom center;
  color: #000000;
  font-weight: 300;
  font-size: 27px; }
