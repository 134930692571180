/*!
  Theme Name: WPTheme
  Author: Denis Gerasimov
  Author URI: gerasimov@webdenis.ru
  Version: 1.0*/
@import "config/variables";
@import "config/grid";
@import "config/mixins";

@import "fonts";
@import "normalize";

@import "common/common";
@import "common/image";
@import "common/heading";
@import "common/link";
@import "common/text";
@import "common/table";
@import "common/table-responsive";
@import "common/logo";

@import "form/form";
@import "form/button";
@import "form/input";
@import "form/select";

@import "modules/carousel";
@import "modules/list";
@import "modules/menu";
@import "modules/modal";
@import "modules/pagination";
@import "modules/tabs";

@import "base";
@import "grid";

@import "content/header";
@import "content/footer";
@import "content/content";

@import "pages/home";
@import "pages/service";
@import "pages/gallery";
