.footer {
  margin-top: 60px;
  padding: 30px 0;
  background-color: #192a39;
  color: #ffffff;

  .container {
    @include clearfix; } }

.footer-contacts {
  float: left;
  margin-left: 30px; }

.footer-socials {
  float: left;
  margin-left: 30px; }

.footer-socials-links {
  a {
    margin: 0 5px;

    &:hover {
      color: #ffffff;
      border-bottom-color: transparent; } } }

.footer-phone {
  float: right;
  text-align: center;

  .text-phone {
    margin-bottom: 15px;
    color: #ffffff;
    line-height: 1; } }

@include bp($screen-lg, max) {
  .logo-footer,
  .footer-contacts,
  .footer-socials,
  .footer-phone {
    float: none;
    width: auto;
    margin-left: 0;
    margin-top: 40px;
    text-align: center; }

  .logo-footer {
    margin-top: 0;

    img {
      margin: 0 auto; } } }
