/**/

.list-services {
  margin-left: -15px;
  margin-right: -15px;
  @include clearfix;

  @include bp($screen-600, max) {
    margin-left: 0;
    margin-right: 0; }

  .list-item {
    float: left;
    width: percentage(1/3);
    padding: 20px 15px 0;

    @include bp($screen-sm, max) {
      width: percentage(1/2); }

    @include bp($screen-600, max) {
      float: none;
      width: 100%;
      padding: 20px 0 0; } }

  .list-item-content {
    padding: 10px 25px 25px;
    border: 1px solid #e6e6e6; }

  .list-item-image {
    height: 115px;
    @include valign(); }

  .list-item-name {
    display: block;
    padding-bottom: 15px;
    margin-top: 5px;
    font-size: 25px;
    font-weight: 600;
    line-height: 1;
    background: url("#{$img}line2.svg") no-repeat bottom center;

    &:hover {
      border-bottom-color: transparent;
      color: $color-text; } }

  .list-item-text {
    margin-top: 15px;
    color: #666666; } }

/**/

.list-advantages {
  margin-left: -15px;
  margin-right: -15px;
  @include clearfix;

  @include bp($screen-600, max) {
    margin-left: 0;
    margin-right: 0; }

  .list-item {
    float: left;
    width: percentage(1/4);
    padding: 20px 15px 0;

    @include bp($screen-sm, max) {
      width: percentage(1/2); }

    @include bp($screen-600, max) {
      float: none;
      width: 100%;
      padding: 20px 0 0; } }

  .list-item-content {}

  .list-item-image {
    height: 55px;
    @include valign();

    .icon {
      width: 55px;
      height: 55px;
      color: $color-green; } }

  .list-item-text {
    margin-top: 15px;
    color: #666666; } }

/**/

.list-news {
  margin-left: -15px;
  margin-right: -15px;
  @include clearfix;

  @include bp($screen-sm, max) {
    margin-left: 0;
    margin-right: 0; }

  .list-item {
    float: left;
    width: percentage(1/3);
    padding: 20px 15px;

    @include bp($screen-md, max) {
      width: percentage(1/2); }

    @include bp($screen-sm, max) {
      float: none;
      width: 100%;
      padding: 20px 0; } }

  .list-item-content {}

  .list-item-image {
    border: 1px solid #e6e6e6;

    &.padded {
      padding: 20px 10px; }

    @include bp($screen-sm, max) {
      text-align: center;
      background-color: #eeeeee; } }

  .list-item-text {
    margin-top: 15px;
    color: #666666;

    p {
      margin-top: 10px; } }

  .list-item-name {
    display: block;
    margin-top: 5px;
    font-size: 20px;
    font-weight: 600;
    line-height: 1;

    &:hover {
      border-bottom-color: transparent;
      color: $color-text; } }

  .list-item-meta {
    margin-top: 10px;
    font-style: italic;
    font-weight: 400;

    .icon {
      margin-right: 2px;
      vertical-align: -2px; } } }

.list-news-more {
  margin-top: 40px;
  text-align: center;

  .button {
    padding-left: 70px;
    padding-right: 70px; } }

/**/

.list-news-inner {
  .list-item {
    @include clearfix; }

  .list-item-image {
    float: left;
    width: 375px;
    border: 1px solid #e6e6e6;

    @include bp($screen-md, max) {
      width: 300px;
      background-color: #eeeeee; }

    @include bp($screen-sm, max) {
      float: none;
      width: auto;
      text-align: center; } }

  .list-item-text {
    padding-left: 425px;

    @include bp($screen-md, max) {
      padding-left: 330px; }

    @include bp($screen-sm, max) {
      padding-left: 0;
      margin-top: 40px; } }

  .list-item-meta {
    margin-top: 10px;
    font-style: italic;
    font-weight: 400;

    .icon {
      margin-right: 2px;
      vertical-align: -2px; } }

  .list-item-more {
    margin-top: 40px;

    .button {
      height: 40px;
      line-height: 40px;
      font-size: 18px;

      @include bp($screen-xs, max) {
        width: 100%;
        padding-left: 0;
        padding-right: 0; } } } }

/**/

.list-contacts {
  text-align: center;

  .list-item {
    vertical-align: top;
    display: inline-block;
    width: 300px;
    margin-left: 90px;
    @include clearmarginfirst(left);

    @include bp($screen-lg, max) {
      margin-left: 20px; }

    @include bp($screen-md, max) {
      display: block;
      width: 100%;
      margin-left: 0;
      margin-top: 40px; } }

  .list-item-image {
    display: block;
    width: 156px;
    height: 156px;
    margin: 0 auto 25px;
    border: 1px solid #e6e6e6;
    border-radius: 50%;
    text-align: center;
    @include valign();

    .icon {
      color: $color-green; }

    @include bp($screen-sm, max) {
      display: none; } }

  .list-item-text {
    line-height: 1.8;

    .text-phone {
      line-height: 1.5; } } }

/**/

.list-service-icons {
  margin-left: -15px;
  margin-right: -15px;
  @include clearfix;

  @include bp($screen-600, max) {
    margin-left: 0;
    margin-right: 0; }

  .list-item {
    float: left;
    width: percentage(1/4);
    padding: 10px 30px 0;
    text-align: center;

    @include bp($screen-sm, max) {
      float: none;
      width: 100%;
      padding: 40px 0 0; } }

  .list-item-image {
    display: block;
    margin-bottom: 15px;
    text-align: center;

    .icon {
      width: 60px;
      height: 60px;
      color: $color-green; } }

  .list-item-text {
    color: #666666; } }

/**/

.list-gallery {
  text-align: center;
  font-size: 0;

  .list-item {
    display: inline-block;
    padding: 10px; }

  .list-item-image {
    display: block;
    padding: 10px;
    border: 1px solid #e6e6e6; } }

/**/

.list-responses {
  max-width: 800px;
  margin: 0 auto;

  .list-item {}

  .list-item-image {
    float: left; }

  .list-item-content {
    padding-left: 340px; }

  .list-item-title {
    font-weight: bold; }

  .list-item-text {
    margin-top: 20px; }

  @include bp($screen-md, max) {
    .list-item-image {
      width: 200px; }

    .list-item-content {
      padding-left: 240px; } }

  @include bp($screen-sm, max) {
    .list-item-image {
      float: none;
      width: auto;
      text-align: center; }

    .list-item-content {
      padding-left: 0;
      margin-top: 20px; } } }
