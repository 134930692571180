.tabs-controls {
  display: table;
  width: 100%; }

.tabs-control {
  vertical-align: middle;
  display: table-cell;
  width: percentage(1/5);
  height: 65px;
  padding-left: 10px;
  padding-right: 20px;
  border: 0;
  outline: 0;
  background-color: #f2f2f2;
  text-align: left;
  color: #4d4d4d;
  line-height: 1;
  font-family: $font1;
  font-size: 18px;
  font-weight: 500;
  @include clearfix;

  .icon {
    vertical-align: middle;
    height: 44px;
    width: 44px; }

  span {
    vertical-align: middle;
    display: inline-block;
    max-width: 65%; }

  &.active {
    color: #ffffff;
    background-color: $color-green; }

  @include bp($screen-md, max) {
    text-align: center;

    .icon {
      float: none; }

    span {
      display: none; } } }

.tabs-content {
  display: table;

  @include bp($screen-sm, max) {
    display: block; } }
