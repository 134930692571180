table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;

  th,
  td {
    padding: 0 20px;
    border-left: 1px solid #e6e6e6;
    border-right: 1px solid #e6e6e6;
    line-height: 1;

    &.aligncenter {
      text-align: center; } }

  th {
    vertical-align: middle;
    height: 50px;
    border-color: #00ad94;
    text-transform: none;
    color: #ffffff;
    background-color: $color-green;
    text-align: left;
    font-size: 22px;
    font-weight: 400; }

  td {
    overflow: hidden;
    vertical-align: middle;
    height: 52px;
    text-align: left;
    font-size: 20px;
    font-weight: 500; }

  tr {
    &:last-child {
      td {
        border-bottom: 1px solid #e6e6e6; } }

    &:nth-child(even) {
      td {
        background-color: #f2f2f2; } } } }
