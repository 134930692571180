body.home {
  .menu-services {
    margin-bottom: 0; }

  .footer {
    margin-top: 0; }

  @include bp($screen-md, min) {
    .wrapper {
      padding-top: 152px; } } }

/* Sections */

.section-home-banner {
  padding-top: 60px;
  padding-bottom: 90px;
  background: url("#{$img}banner.jpg") no-repeat top center #ffffff;
  text-align: center;

  .heading {
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
    color: #ffffff;
    background: none;
    font-weight: 700;
    font-size: 55px;

    strong {
      color: $color-green;
      font-weight: 700; } }

  .text {
    max-width: 770px;
    margin: 30px auto 0;
    color: #ffffff;
    font-size: 22px; }

  .button {
    margin-top: 40px; }

  @include bp($screen-md, max) {
    .heading {
      font-size: 40px; } }

  @include bp($screen-sm, max) {
    .heading {
      font-size: 32px; }

    .text {
      p {
        display: none; } }

    .home-icons {
      font-size: 16px;

      .icon {
        display: block;
        margin: 0 auto 10px; } } } }

/**/

.section-home-services {
  padding: 45px 0 60px;
  text-align: center;

  p {
    max-width: 740px;
    margin-left: auto;
    margin-right: auto; } }

/**/

.section-home-pickup {
  padding-top: 55px;
  background-color: #f2f2f2;

  .container {
    background: url("#{$img}car.png") no-repeat 70px bottom;
    @include clearfix;

    @include bp($screen-lg, max) {
      background-position: 50px bottom; }

    @include bp($screen-md, max) {
      background: none; } } }

/**/

.section-home-pickup-text {
  float: right;
  width: 550px;
  padding-bottom: 160px;
  margin-right: 90px;
  background: url("#{$img}box.png") no-repeat center bottom;
  text-align: center;

  @include bp($screen-lg, max) {
    margin-right: 0; }

  @include bp($screen-md, max) {
    float: none;
    width: 100%;
    margin-right: 0; }

  .button {
    margin-top: 25px; } }

/**/

.section-home-advantages {
  padding-top: 45px;
  padding-bottom: 45px;
  border-bottom: 1px solid #dddddd;
  text-align: center;

  .text {
    float: left;
    width: 50%;
    padding-left: 40px;
    padding-top: 40px;
    text-align: left;
    font-size: 20px;

    @include bp($screen-md, max) {
      float: none;
      width: auto;
      padding-left: 0;
      padding-top: 0;
      text-align: center; } }

  .image {
    float: right;
    width: 50%;
    padding-left: 80px;
    text-align: center;

    @include bp($screen-md, max) {
      float: none;
      width: auto;
      padding-top: 80px;
      padding-left: 0; } } }

/**/

.section-home-responses {
  padding-top: 45px;
  padding-bottom: 80px; }

/**/

.section-home-gallery {
  margin-top: 45px;
  padding-top: 45px;
  border-top: 1px solid #dddddd;
  text-align: center; }

/**/

.section-home-contacts {
  margin-top: 60px;
  @include clearfix;

  .section-map {
    float: right;
    width: 65%;
    height: 530px;
    margin-top: 0;

    @include bp($screen-md, max) {
      float: none;
      width: 100%; }

    @include bp(900px, max, height) {
      height: 300px; } }

  .section-map-image {
    float: left;
    width: 35%;
    padding-top: 40px;
    padding-left: 40px;
    padding-right: 40px;

    .heading {
      text-align: left;
      background-position: bottom left; }

    @include bp($screen-md, max) {
      float: none;
      width: 100%;
      padding-bottom: 40px; } } }

/* Other */

.home-icons {
  color: #ffffff;
  font-size: 24px;

  div {
    margin-top: 20px;
    @include clearmarginfirst(top); }

  .icon {
    vertical-align: -12px;
    margin-right: 12px; } }
