.header {
  height: $header-height;
  background-color: #ffffff;

  .container {
    @include clearfix; }

  .inphone {
    display: none; }

  @include bp($screen-md, max) {
    margin-bottom: 30px;
    border-bottom: 1px solid #EEEEEE;

    .inphone {
      display: block;
      line-height: 1;
      margin-top: -30px;
      text-align: right;
      font-weight: bold; } } }

.header-contacts {
  float: right;
  height: $header-height;
  padding-top: 27px;
  text-align: center;

  @include bp($screen-md, max) {
    float: none;
    height: auto;
    padding: 25px 0;
    border-top: 1px solid #4cd2c0;
    text-align: center;
    line-height: 1;

    .text-phone {
      color: #ffffff;
      font-size: 25px; } } }

.header-block {
  @include bp($screen-md, max) {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: 45px 50px;
    background-color: $color-green;
    @include position(fixed, 0, 0);
    @include transform(translateY(-150%));
    @include transition(transform .5s ease-in-out);
    @include clearfix;
    @include zindex(7); }

  &.active {
    @include transform(translateX(0%)); } }
