$fonts: "fonts/";

/* 300 */
@font-face {
  font-family: "Myriad Set Pro";
  src: url("#{$fonts}myriad-set-pro_thin-webfont.eot");
  src: url("#{$fonts}myriad-set-pro_thin-webfont.eot?#iefix") format("embedded-opentype"), url("#{$fonts}myriad-set-pro_thin-webfont.woff2") format("woff2"), url("#{$fonts}myriad-set-pro_thin-webfont.woff") format("woff"), url("#{$fonts}myriad-set-pro_thin-webfont.ttf") format("truetype"), url("#{$fonts}myriad-set-pro_thin-webfont.svg#myriad_set_prothin") format("svg");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: "Myriad Set Pro";
  src: url("#{$fonts}myriad-set-pro_thin-italic-webfont.eot");
  src: url("#{$fonts}myriad-set-pro_thin-italic-webfont.eot?#iefix") format("embedded-opentype"), url("#{$fonts}myriad-set-pro_thin-italic-webfont.woff2") format("woff2"), url("#{$fonts}myriad-set-pro_thin-italic-webfont.woff") format("woff"), url("#{$fonts}myriad-set-pro_thin-italic-webfont.ttf") format("truetype"), url("#{$fonts}myriad-set-pro_thin-italic-webfont.svg#myriad_set_prothin_italic") format("svg");
  font-weight: 300;
  font-style: italic; }

/* 400 */
@font-face {
  font-family: "Myriad Set Pro";
  src: url("#{$fonts}myriad-set-pro_text-webfont.eot");
  src: url("#{$fonts}myriad-set-pro_text-webfont.eot?#iefix") format("embedded-opentype"), url("#{$fonts}myriad-set-pro_text-webfont.woff2") format("woff2"), url("#{$fonts}myriad-set-pro_text-webfont.woff") format("woff"), url("#{$fonts}myriad-set-pro_text-webfont.ttf") format("truetype"), url("#{$fonts}myriad-set-pro_text-webfont.svg#myriad_set_protext") format("svg");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: "Myriad Set Pro";
  src: url("#{$fonts}myriad-set-pro_text-italic-webfont.eot");
  src: url("#{$fonts}myriad-set-pro_text-italic-webfont.eot?#iefix") format("embedded-opentype"), url("#{$fonts}myriad-set-pro_text-italic-webfont.woff2") format("woff2"), url("#{$fonts}myriad-set-pro_text-italic-webfont.woff") format("woff"), url("#{$fonts}myriad-set-pro_text-italic-webfont.ttf") format("truetype"), url("#{$fonts}myriad-set-pro_text-italic-webfont.svg#myriad_set_protext_italic") format("svg");
  font-weight: 400;
  font-style: italic; }

/* 500 */
@font-face {
  font-family: "Myriad Set Pro";
  src: url("#{$fonts}myriad-set-pro_medium-webfont.eot");
  src: url("#{$fonts}myriad-set-pro_medium-webfont.eot?#iefix") format("embedded-opentype"), url("#{$fonts}myriad-set-pro_medium-webfont.woff2") format("woff2"), url("#{$fonts}myriad-set-pro_medium-webfont.woff") format("woff"), url("#{$fonts}myriad-set-pro_medium-webfont.ttf") format("truetype"), url("#{$fonts}myriad-set-pro_medium-webfont.svg#myriad_set_promedium") format("svg");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: "Myriad Set Pro";
  src: url("#{$fonts}myriad-set-pro_medium-italic-webfont.eot");
  src: url("#{$fonts}myriad-set-pro_medium-italic-webfont.eot?#iefix") format("embedded-opentype"), url("#{$fonts}myriad-set-pro_medium-italic-webfont.woff2") format("woff2"), url("#{$fonts}myriad-set-pro_medium-italic-webfont.woff") format("woff"), url("#{$fonts}myriad-set-pro_medium-italic-webfont.ttf") format("truetype"), url("#{$fonts}myriad-set-pro_medium-italic-webfont.svg#myriad_set_promedium_italic") format("svg");
  font-weight: 500;
  font-style: italic; }

/* 600 */
@font-face {
  font-family: "Myriad Set Pro";
  src: url("#{$fonts}myriad-set-pro_semibold-webfont.eot");
  src: url("#{$fonts}myriad-set-pro_semibold-webfont.eot?#iefix") format("embedded-opentype"), url("#{$fonts}myriad-set-pro_semibold-webfont.woff2") format("woff2"), url("#{$fonts}myriad-set-pro_semibold-webfont.woff") format("woff"), url("#{$fonts}myriad-set-pro_semibold-webfont.ttf") format("truetype"), url("#{$fonts}myriad-set-pro_semibold-webfont.svg#myriad_set_prosemibold") format("svg");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: "Myriad Set Pro";
  src: url("#{$fonts}myriad-set-pro_semibold-italic-webfont.eot");
  src: url("#{$fonts}myriad-set-pro_semibold-italic-webfont.eot?#iefix") format("embedded-opentype"), url("#{$fonts}myriad-set-pro_semibold-italic-webfont.woff2") format("woff2"), url("#{$fonts}myriad-set-pro_semibold-italic-webfont.woff") format("woff"), url("#{$fonts}myriad-set-pro_semibold-italic-webfont.ttf") format("truetype"), url("#{$fonts}myriad-set-pro_semibold-italic-webfont.svg#myriad_set_prosemibold_italic") format("svg");
  font-weight: 600;
  font-style: italic; }

/* 700 */
@font-face {
  font-family: "Myriad Set Pro";
  src: url("#{$fonts}myriad-set-pro_bold-webfont.eot");
  src: url("#{$fonts}myriad-set-pro_bold-webfont.eot?#iefix") format("embedded-opentype"), url("#{$fonts}myriad-set-pro_bold-webfont.woff2") format("woff2"), url("#{$fonts}myriad-set-pro_bold-webfont.woff") format("woff"), url("#{$fonts}myriad-set-pro_bold-webfont.ttf") format("truetype"), url("#{$fonts}myriad-set-pro_bold-webfont.svg#myriad_set_probold") format("svg");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: "Myriad Set Pro";
  src: url("#{$fonts}myriad-set-pro_bold-italic-webfont.eot");
  src: url("#{$fonts}myriad-set-pro_bold-italic-webfont.eot?#iefix") format("embedded-opentype"), url("#{$fonts}myriad-set-pro_bold-italic-webfont.woff2") format("woff2"), url("#{$fonts}myriad-set-pro_bold-italic-webfont.woff") format("woff"), url("#{$fonts}myriad-set-pro_bold-italic-webfont.ttf") format("truetype"), url("#{$fonts}myriad-set-pro_bold-italic-webfont.svg#myriad_set_probold_italic") format("svg");
  font-weight: 700;
  font-style: italic; }
