.form-control {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  height: 50px;
  padding: 0 15px;
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  outline: 0;
  background-color: #ffffff;
  color: $color-text;
  font-family: $font1;
  font-size: 18px;
  line-height: 50px;
  @include transition(box-shadow .15s);
  @include placeholder(#999999);

  &:hover {
    box-shadow: inset 0 0 0 1px #e6e6e6; }

  &:focus {
    border-color: #cbcbcb;
    box-shadow: 0 0 0 1px #cbcbcb; }

  &.error {
    border-color: $color-red;
    background-color: desaturate(lighten($color-red, 40%), 10%);
    color: $color-red;
    @include placeholder($color-red);

    &:hover {
      box-shadow: inset 0 0 0 1px $color-red; }

    &:focus {
      box-shadow: 0 0 0 1px $color-red; } } }

textarea.form-control {
  height: 120px;
  padding-top: 15px;
  line-height: 1.3;
  resize: vertical; }
