.logo-header {
  float: left;
  height: $header-height;
  padding-bottom: 5px;
  line-height: $header-height;
  @include valign();

  .logo-image {
    width: 213px;
    margin-bottom: 8px;

    @include bp($screen-xs, max) {
      width: 180px; } } }

.logo-footer {
  float: left;

  .logo-image {
    display: block;
    width: 213px;

    @include bp($screen-md, max) {
      margin: 0 auto; } }

  .logo-copy {
    display: block;
    margin-top: 15px;
    font-size: 16px; } }
