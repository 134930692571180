*,
*:before,
*:after {
  box-sizing: border-box; }

html,
body {
  height: 100%; }

body {
  overflow-y: scroll;
  background-color: #ffffff;
  color: $color-text;
  font-family: $font1;
  font-size: $font-size;
  font-weight: 300;
  line-height: $line-height;

  &.modal-open {
    overflow: hidden; }

  @if $wp {
    &#tinymce {
      padding: 20px !important;
      background: #ffffff !important; } } }

@include bp($screen-md, min) {
  .header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000; }

  .menu-services {
    position: fixed;
    top: $header-height;
    left: 0;
    right: 0;
    z-index: 1000; }

  .wrapper {
    padding-top: 175px; } }
