img {
  vertical-align: top;
  max-width: 100%;

  &.bordered {
    border: 2px solid $color-text;
    box-shadow: 0 0 0 2px $color-text; }

  &.alignleft {
    display: block;
    margin: 0 auto;

    @include bp($screen-xs) {
      float: left;
      margin: 0 30px 20px 0; } }

  &.alignright {
    display: block;
    margin: 0 auto;

    @include bp($screen-xs) {
      float: right;
      margin: 0 0 20px 30px; } }

  &.aligncenter {
    display: block;
    margin: 0 auto; } }

figure {
  margin: 20px 0; }

figcaption {
  padding-top: 10px;
  text-align: center;
  font-size: $font-size - 2; }

.icon {
  display: inline-block;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
  @include transition((fill .15s, transform .3s)); }
