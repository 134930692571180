.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

/* Modal */

.modal {
  display: none;
  overflow-x: hidden;
  overflow-y: scroll;
  @include bgrgba(#000000, .5);
  @include overlay();
  @include zindex(10); }

.modal-content {
  position: relative;
  width: 600px;
  padding: 40px;
  margin: 100px auto 20px;
  background-color: #fff;
  box-shadow: 0 0 20px rgba(#000, .5);
  @include transition(transform .5s cubic-bezier(.6, 0, .4, 1));
  @include transform(translateY(-150%));
  @include zindex(11);

  &.active {
    @include transform(translateY(0%)); }

  @include bp($screen-sm, max) {
    width: 95%;
    margin: 20px auto; } }

/* Types */

/* Elements */

.modal-name {
  padding-bottom: 25px;
  margin-bottom: 25px;
  text-align: center;
  background: url("#{$img}line.png") no-repeat bottom center;
  color: #000000;
  font-weight: 400;
  font-size: 28px;
  line-height: 1; }

.modal-header {
  margin-bottom: 30px;
  text-align: center; }

.modal-close {
  display: block;
  width: 48px;
  height: 48px;
  margin-top: 10px;
  margin-right: 10px;
  text-align: center;
  font-size: 24px;
  line-height: 46px;
  cursor: pointer;
  @include position(absolute, 0, false, 0);
  @include transition(color .15s);

  &::before {
    content: "✕"; }

  &:hover {
    color: $color-green; } }
